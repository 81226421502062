<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '编辑' : '添加') + '销售方'" :width="500" @cancel="close" @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
      <a-form-model-item label="姓名" prop="name">
            <a-input v-model="form.name" style="width:350px;"/>
      </a-form-model-item>
      <a-form-model-item label="支付公司" prop="companyCode">
        <a-select
        v-model="form.companyCode"
        placeholder="请选择" style="width:350px;">
          <a-select-option
            :value="item.code"
            v-for="(item, index) in companyList"
            :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否分账:"  style="margin-left: 5px" >
        <a-radio-group v-model="form.settle">
          <a-radio :value=0>否</a-radio>
          <a-radio :value=1>是</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="appid" prop="appid">
          <a-input v-model="form.appid" placeholder="请输入" style="width:350px;"/>
      </a-form-model-item>
      <a-form-model-item label="商户号" prop="mchid">
          <a-input v-model="form.mchid"  placeholder="请输入" style="width:350px;"/>
      </a-form-model-item>
      <a-form-model-item label="商户秘钥" prop="mchkey" >
          <a-input v-model="form.mchkey" placeholder="请输入" style="width:350px;"/>
      </a-form-model-item>
      <a-form-model-item label="API3秘钥" prop="apiV3Key" >
          <a-input v-model="form.apiV3Key" placeholder="请输入" style="width:350px;"/>
      </a-form-model-item>
      <a-form-model-item label="API3私钥路径" prop="privateKeyPath" >
          <a-input v-model="form.privateKeyPath" placeholder="请输入" style="width:350px;"/>
      </a-form-model-item>
      <a-form-model-item label="API3公钥路径" prop="privateCertPath">
          <a-input v-model="form.privateCertPath" placeholder="请输入" style="width:350px;"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  inject: ['appRefs'],
  data () {
    return {
      isShow: false,
      isDetermineLoading: false,
      form: {},
      companyList: [], // 支付公司列表
      formRules: {
        name: [{ required: true, message: '请输入销售方名称', trigger: 'change' }],
        companyCode: [{ required: true, message: '请选择支付公司', trigger: 'change' }],
        appid: [{ required: true, message: '请输入appid', trigger: 'change' }],
        mchid: [{ required: true, message: '请输入商户号', trigger: 'change' }],
        mchkey: [{ required: true, message: '请输入商户密钥', trigger: 'change' }],
        apiV3Key: [{ required: true, message: '请输入API3秘钥', trigger: 'change' }],
        privateKeyPath: [{ required: true, message: '请输入API3私钥路径', trigger: 'change' }],
        privateCertPath: [{ required: true, message: '请输入API3公钥路径', trigger: 'change' }]
      },
      treeData: []
    }
  },
  watch: {
  },
  methods: {
    // 设置表单
    setForm (form = {}) {
      this.getCompanyList()
      this.resetForm()
      this.form = {
        id: form.id,
        name: form.name,
        appid: form.appid,
        companyCode: form.companyCode,
        settle: form.settle,
        mchid: form.mchid,
        mchkey: form.mchkey,
        privateKeyPath: form.privateKeyPath,
        privateCertPath: form.privateCertPath,
        apiV3Key: form.apiV3Key
      }
      if (!this.form.settle) {
        this.form.settle = 0
      }
    },
    resetForm () {
      this.companyList = []
      this.form = {
        id: '',
        name: '',
        appid: '',
        companyCode: '',
        settle: '',
        mchid: '',
        mchkey: '',
        privateKeyPath: '',
        privateCertPath: '',
        apiV3Key: ''
      }
    },
    // 查询支付公司列表
    async getCompanyList () {
      const res = await this.$store.dispatch('http', {
        api: 'getCompanyList'
      })
      if (res) {
        const that = this
        res.map(item => {
          that.companyList.push({ code: item.code, name: item.name })
        })
      }
    },
    // 关闭
    close () {
      this.setForm()
      this.$refs.form.resetFields()
      this.resetForm()
    },
    // 确定
    async determine (e) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = {
            id: this.form.id,
            name: this.form.name,
            channel: {
              appid: this.form.appid,
              companyCode: this.form.companyCode,
              settle: this.form.settle,
              mchid: this.form.mchid,
              mchkey: this.form.mchkey,
              privateKeyPath: this.form.privateKeyPath,
              privateCertPath: this.form.privateCertPath,
              apiV3Key: this.form.apiV3Key
            }
          }
          let query = {}
          let api = 'addSeller'
          if (params.id) {
            api = 'modifySeller'
            query = {
              id: this.form.id
            }
          }
          await this.$store.dispatch('http', {
            api,
            params,
            query,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (params.id ? '编辑' : '添加') + '销售方成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 16px 0 10px;
  }
}
</style>
